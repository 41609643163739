<template>
    <main id="content">
      <div class="container">
        <div class="row">
            <div class="col-12 mt-05 mb-5">
            <!-- big grid 1 -->
            <div class="row featured-1">
              <div class="col-md-6 pb-05 pt-05 pe-md-05">
                <div class="card card-full text-light overflow zoom">
                  <div class="height-ratio image-wrapper" v-for="publish in topsection.slice(0,1)" :key="publish.id">
                    <a @click="getFeed(publish)" class="pointer">
                      <img class="img-harry" v-lazy="publish.open_graph_image"  :alt="publish.post_title.substring(0,45)">
                    </a>
                    <div class="position-absolute p-3 b-0 w-100 bg-lg-shadow content-container-cat">
                      <a @click="getFeed(publish)" class="pointer">
                        <h3 class="h1-sm h2-md display-4-lg fw-500 text-white arabic-kufi text-harry">{{ publish.post_title.substring(0,70) }}</h3>
                      </a>
                      <div class="news-meta arabic-kufi">
                        <time class="news-date" datetime="2019-10-22">{{ publish.post_date.substring(0,10) }}</time>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 pt-05 ps-md-05">
                <div class="row newsbox">
                  <article class="col-6" v-for="publish in topsection.slice(1,5)" :key="publish.id">
                    <div class="card card-full text-white overflow zoom">
                      <div class="height-ratio image-wrapper">
                        <a @click="getFeed(publish)" class="pointer">
                          <img class="img-harry1" v-lazy="publish.open_graph_image"  :alt="publish.post_title">
                        </a>
                        <a class="p-1 badge bg-primary text-white top-right" >{{ publish.name }}</a>
                        <div class="position-absolute px-3 pb-3 pt-0 b-0 w-100 bg-shadow arabic-kufi">
                          
                          <a @click="getFeed(publish)">
                            <h3 class="h6 h4-sm h6-md h5-lg text-white my-1 arabic-kufi pointer text-harry-cat">{{ publish.post_title.substring(0,45) }}</h3>
                          </a>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            </div>
            <!-- end big grid 1 -->
          </div>
          <!--start left column-->
          <div class="col-md-12">
            <!--Block start-->
            <div class="block-area">
              <!--block title-->
              <div class="block-title-6">
                <h4 class="h5 border-primary">
                  <span class="bg-primary text-white">منوعات</span>
                </h4>
              </div>
              <div class="entry-footer" style="margin-bottom: 1.5rem;margin-top: 2rem;">
              <!-- tags -->
              <div class="tags-area">
                <ul class="tags-links tagcloud ps-0">
                  <li><span class="fw-bold me-2">تصفح التصنيفات</span></li>
                  <li><router-link to="/Economie">إقتصاد</router-link></li>
                  <li><router-link to="/Accident">حوادث</router-link></li>
                  <li><router-link to="/Sport">رياضة</router-link></li>
                  <li><router-link to="/Policy">سياسية</router-link></li>
                  <li><router-link to="/Health">صحة</router-link></li>
                  <li><router-link to="/Technology">علوم وتكنلوجيا</router-link></li>
                </ul>
              </div>
              <div class="gap-0"></div>
            </div>
              <!-- block content -->
              <div class="row">
                <!--post list-->
                <article class="col-sm-6 col-md-12 col-lg-4" v-for="publish in topsection.slice(5,20)" :key="publish.id">
                  <!--thumbnail-->
                    <div class="ratio_360-202 image-wrapper">
                      <a @click="getFeed(publish)" class="pointer">
                        <img class="img-fluid" v-lazy="publish.open_graph_image" alt="">
                      </a>
                    </div>
                    <div class="card-body">
                      <!-- title -->
                      <h2 class="card-title h1-sm h3-lg  pointer text-harry-cat">
                        <a @click="getFeed(publish)">{{ publish.post_title.substring(0,70) }}</a>
                      </h2>
                      <!-- author, date and comments -->
                      <div class="card-text mb-2 text-muted small">
                        <span class="d-none d-sm-inline me-1 ">
                          <a class="fw-bold" href="#">تاق برس</a>
                        </span>
                        <time datetime="2019-10-22">{{ publish.post_date.substring(0,10) }}</time>
                      </div>
                      <!--description-->
                    </div>
                </article>
                <div class="gap-05"></div>
              </div>
              <!-- end block content -->
            </div>
            <!--End Block-->
          </div>
          <!--end left column-->
          <div class="clearfix my-4">
              <nav class="float-start" aria-label="Page navigation example pointer">
                  <paginate
                      :page-count="4"
                      :click-handler="clickCallback"
                      :prev-text="'السابق'"
                      :next-text="'التالي'"
                      :container-class="'pagination'"
                      :page-class="'page-item'">
                  </paginate>
              </nav>
          </div>
        </div>
      </div>
    </main>
</template>

<script>
import { HTTP } from '@/Api/http-common';
import { useRouter, useRoute } from "vue-router";
import { ref } from '@vue/reactivity';
import { useCookie } from 'vue-cookie-next';
import Paginate from 'vuejs-paginate-next';
import { useMeta } from 'vue-meta'
export default {
  name: 'Mixs',
  components: {
     paginate: Paginate,
  },
   async setup() {
    useMeta({ title: 'منوعات' })
      const router = useRouter();
      const route = useRoute();
      const cookie = useCookie()
      const topsection = ref([]);

      try {
        await HTTP.get("getPostbyCateogry.php?getPostbyCateogry&term_id=10&LIMIT=20&OFFSET=0").then((res) => {
          topsection.value = res.data.getPostbyCateogry; 
        });
      } catch (err) {
        console.log(err);
      }

      const clickCallback = async (pageNum) => {
       if (pageNum == 1) {
        await HTTP.get(`getPostbyCateogry.php?getPostbyCateogry&term_id=10&LIMIT=20&OFFSET=0`).then(res => {
                topsection.value = res.data.getPostbyCateogry;
          })
       } else if (pageNum == 2) {
         await HTTP.get(`getPostbyCateogry.php?getPostbyCateogry&term_id=10&LIMIT=20&OFFSET=20`).then(res => {
                topsection.value = res.data.getPostbyCateogry;
          })
       } else if (pageNum == 3) {
         await HTTP.get(`getPostbyCateogry.php?getPostbyCateogry&term_id=10&LIMIT=20&OFFSET=40`).then(res => {
                topsection.value = res.data.getPostbyCateogry;
          })
       } else if (pageNum == 4) {
         await HTTP.get(`getPostbyCateogry.php?getPostbyCateogry&term_id=10&LIMIT=20&OFFSET=60`).then(res => {
                topsection.value = res.data.getPostbyCateogry;
          })
       } else if (pageNum == 5) {
         await HTTP.get(`getPostbyCateogry.php?getPostbyCateogry&term_id=10&LIMIT=20&OFFSET=80`).then(res => {
                topsection.value = res.data.getPostbyCateogry;
          })
       } 
      };

      const getFeed = (publish) => {
        cookie.setCookie("term_id", publish.term_id);
        router.push({ name: "ReadArticle", params: { id: publish.id } });
      };
      return {
        topsection,
        getFeed,
        clickCallback
      };
   },
}
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
</style>